import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "author": "jasonDinsmore",
  "categories": ["rails", "ruby"],
  "date": "2019-06-10",
  "path": "/blog/procto-method-object",
  "summary": "Let's explore how to turn your ordinary Ruby class into an undeniably awesome method object with the ultra-handy gem, Procto.",
  "title": "Have you called your method object lately?",
  "image": "./ogp.jpg",
  "seo": {
    "og": {
      "type": "article",
      "title": "Have you called your method object lately?",
      "description": "Let's explore how to turn your ordinary Ruby class into an undeniably awesome method object with the ultra-handy gem, Procto."
    },
    "twitter": {
      "card": "summary_large_image",
      "site": "@hintmedia",
      "creator": "@dinjas"
    }
  }
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Allow me to introduce you to another library that has proven itself
useful when writing Ruby: the `}<a parentName="p" {...{
        "href": "https://github.com/snusnu/procto"
      }}>{`Procto`}</a>{` gem.`}</p>
    <h2>{`Procto Overview`}</h2>
    <p>{`Procto is a gem that lets you turn a Ruby class into a method object.
This helps clean up code that instantiates a class
to perform a single task, calculation, etc.`}</p>
    <p>{`To better understand what it's trying to facilitate, perhaps a review of method
objects in Ruby would help.`}</p>
    <h3>{`Ruby Method Objects`}</h3>
    <p>{`A method object is essentially an object that has a bound context and provides a
single method.`}</p>
    <p>{`Consider this example:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ruby"
    }}><pre parentName="div" {...{
        "className": "language-ruby"
      }}><code parentName="pre" {...{
          "className": "language-ruby"
        }}>{`  `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` meth_obj `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token symbol"
          }}>{`:foo`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token symbol"
          }}>{`:bar`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token symbol"
          }}>{`:baz`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`method`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token symbol"
          }}>{`:size`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
  `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token builtin"
          }}>{`Array`}</span><span parentName="code" {...{
            "className": "token comment"
          }}>{`#size()`}</span>{`
  `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` meth_obj`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token keyword"
          }}>{`class`}</span>{`
  `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token builtin"
          }}>{`Method`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{` `}<span parentName="code" {...{
            "className": "token builtin"
          }}>{`Object`}</span>{` 
  `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` meth_obj`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`call
  `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`3`}</span></code></pre></div>
    <p>{`Above, `}<inlineCode parentName="p">{`meth_obj`}</inlineCode>{` is a method object that is bound to the array containing
`}<inlineCode parentName="p">{`[:foo, :bar, :baz]`}</inlineCode>{`. In this case, when `}<inlineCode parentName="p">{`call`}</inlineCode>{` is executed on the method
object, the `}<inlineCode parentName="p">{`size`}</inlineCode>{` message is passed to the context the method object is bound to
(`}<inlineCode parentName="p">{`[:foo, :bar, :baz]`}</inlineCode>{`) and the result is `}<inlineCode parentName="p">{`3`}</inlineCode>{`. Interestingly (though unrelated),
Ruby allows you to unbind and rebind in cases like this.`}</p>
    <p>{`For example:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ruby"
    }}><pre parentName="div" {...{
        "className": "language-ruby"
      }}><code parentName="pre" {...{
          "className": "language-ruby"
        }}>{`  `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` meth_obj `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token symbol"
          }}>{`:foo`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token symbol"
          }}>{`:bar`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token symbol"
          }}>{`:baz`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`method`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token symbol"
          }}>{`:size`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
  `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` meth_obj`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`call
  `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`3`}</span>{`
  `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` meth_obj `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` meth_obj`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`unbind`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`bind`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token symbol"
          }}>{`:waldo`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token symbol"
          }}>{`:fred`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
  `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` meth_obj`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`call
  `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`2`}</span></code></pre></div>
    <h2>{`Use`}</h2>
    <p>{`To use Procto, you'll need to ensure you've got the gem installed and that it
has been loaded by your program or application. If the gem is available, but
hasn't been loaded, you can just use `}<inlineCode parentName="p">{`require 'procto'`}</inlineCode>{` at the top of your Ruby
class.`}</p>
    <p>{`Once the gem is loaded and available for you to use, you'll just need to:
`}<inlineCode parentName="p">{`include Procto.call`}</inlineCode>{` right after your class definition, ala:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ruby"
    }}><pre parentName="div" {...{
        "className": "language-ruby"
      }}><code parentName="pre" {...{
          "className": "language-ruby"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`require`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'procto'`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`class`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`Foo`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`include`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`Procto`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`call

  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`def`}</span>{` `}<span parentName="code" {...{
            "className": "token method-definition"
          }}><span parentName="span" {...{
              "className": "token function"
            }}>{`initialize`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`bar`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
    `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`@bar`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` bar
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`end`}</span>{`

  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`def`}</span>{` `}<span parentName="code" {...{
            "className": "token method-definition"
          }}><span parentName="span" {...{
              "className": "token function"
            }}>{`call`}</span></span>{`
    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# do stuff`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`end`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`end`}</span></code></pre></div>
    <p>{`When invoking your class, you will supply `}<inlineCode parentName="p">{`call`}</inlineCode>{` with the parameters you would
normally pass to your class's initializer.`}</p>
    <p>{`E.g.:`}</p>
    <p>{`Instead of `}<inlineCode parentName="p">{`Foo.new(bar).call`}</inlineCode>{`, you would do `}<inlineCode parentName="p">{`Foo.call(bar)`}</inlineCode>{`.`}</p>
    <h2>{`Blocks`}</h2>
    <p>{`Although Procto is `}<em parentName="p">{`very`}</em>{` useful, it's not the answer for everything. One thing
you might find yourself wanting to do is pass a block to your `}<inlineCode parentName="p">{`call`}</inlineCode>{` method.
Unfortunately, Procto does not support this.`}</p>
    <p>{`Consider the following code:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ruby"
    }}><pre parentName="div" {...{
        "className": "language-ruby"
      }}><code parentName="pre" {...{
          "className": "language-ruby"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`class`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`FooWithBlock`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`include`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`Procto`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`call

  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`def`}</span>{` `}<span parentName="code" {...{
            "className": "token method-definition"
          }}><span parentName="span" {...{
              "className": "token function"
            }}>{`initialize`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`bar`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
    `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`@bar`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` bar
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`end`}</span>{`

  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`def`}</span>{` `}<span parentName="code" {...{
            "className": "token method-definition"
          }}><span parentName="span" {...{
              "className": "token function"
            }}>{`call`}</span></span>{`
    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# do something`}</span>{`

    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`yield`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`if`}</span>{` block_given`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`?`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`end`}</span>{`

  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`protected`}</span>{`

  attr_reader `}<span parentName="code" {...{
            "className": "token symbol"
          }}>{`:bar`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`end`}</span></code></pre></div>
    <p>{`You might expect to be able to use the above code like this:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ruby"
    }}><pre parentName="div" {...{
        "className": "language-ruby"
      }}><code parentName="pre" {...{
          "className": "language-ruby"
        }}><span parentName="code" {...{
            "className": "token constant"
          }}>{`FooWithBlock`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`call`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'Hello'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`do`}</span>{`
  puts `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"I'm in a block!"`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`end`}</span></code></pre></div>
    <p>{`Sadly, your block will never be executed.`}</p>
    <p>{`If you `}<em parentName="p">{`need`}</em>{` to do the above, you'll have to do so without using Procto, so
you'd need to remove `}<inlineCode parentName="p">{`include Procto.call`}</inlineCode>{` from your class, and invoke your
class as follows:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ruby"
    }}><pre parentName="div" {...{
        "className": "language-ruby"
      }}><code parentName="pre" {...{
          "className": "language-ruby"
        }}><span parentName="code" {...{
            "className": "token constant"
          }}>{`FooWithBlock`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token keyword"
          }}>{`new`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'Hello'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`call `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`do`}</span>{`
  puts `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"I'm in a block!"`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`end`}</span></code></pre></div>
    <h2>{`Bonus`}</h2>
    <p>{`If you haven't used it before, I also highly recommend the
`}<a parentName="p" {...{
        "href": "https://github.com/mbj/concord"
      }}>{`Concord gem`}</a>{`. When used in conjunction with
Procto, it really creates a nice interface for invoking method objects.`}</p>
    <p>{`Concord abstracts away having to define the initializer and attribute accessors
for your class and its attributes. It also happens to play very nicely with
Procto.`}</p>
    <p>{`Using both, you can turn:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ruby"
    }}><pre parentName="div" {...{
        "className": "language-ruby"
      }}><code parentName="pre" {...{
          "className": "language-ruby"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`class`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`Foo`}</span>{`

  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`def`}</span>{` `}<span parentName="code" {...{
            "className": "token method-definition"
          }}><span parentName="span" {...{
              "className": "token function"
            }}>{`initialize`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`bar`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` baz`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
    `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`@bar`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` bar
    `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`@baz`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` baz
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`end`}</span>{`

  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`def`}</span>{` `}<span parentName="code" {...{
            "className": "token method-definition"
          }}><span parentName="span" {...{
              "className": "token function"
            }}>{`call`}</span></span>{`
    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# do stuff`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`end`}</span>{`

  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`protected`}</span>{`

  attr_reader `}<span parentName="code" {...{
            "className": "token symbol"
          }}>{`:bar`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token symbol"
          }}>{`:baz`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`end`}</span>{`

`}<span parentName="code" {...{
            "className": "token constant"
          }}>{`For`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token keyword"
          }}>{`new`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`bar`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` baz`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`call`}</code></pre></div>
    <p>{`Into:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ruby"
    }}><pre parentName="div" {...{
        "className": "language-ruby"
      }}><code parentName="pre" {...{
          "className": "language-ruby"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`class`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`Foo`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`include`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`Concord`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token keyword"
          }}>{`new`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token symbol"
          }}>{`:bar`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token symbol"
          }}>{`:baz`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`include`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`Procto`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`call

  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`def`}</span>{` `}<span parentName="code" {...{
            "className": "token method-definition"
          }}><span parentName="span" {...{
              "className": "token function"
            }}>{`call`}</span></span>{`
    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# do stuff`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`end`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`end`}</span>{`

`}<span parentName="code" {...{
            "className": "token constant"
          }}>{`Foo`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`call`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`bar`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` baz`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span></code></pre></div>
    <p>{`I should mention that Concord limits your initializer to three parameters.
You can work around this by passing a more complex data structure as one
(or more) of the parameters, and extracting the parameters from that object, or
by using the `}<a parentName="p" {...{
        "href": "https://github.com/mbj/anima"
      }}>{`Anima gem`}</a>{` instead, which does not
cap the number of parameters you can pass, but takes an initialization hash
(keyword arguments) instead of parameters.`}</p>
    <h2>{`Summary`}</h2>
    <p>{`Procto is a fantastic tool for cleaning up your class's interface. When used
in conjunction with the Concord gem, you'll find yourself writing significantly
less boilerplate code to get your classes up and running. Your code will also
end up generating a smaller abstract syntax tree (AST), which is generally a
very good thing.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      